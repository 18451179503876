import { NextAuthOptions } from 'next-auth'

import { ProjectSection } from 'graphql/types'

export enum AppMode {
  NPT = 'npt',
  Lv1 = 'lv1',
  Learning = 'learning',
  Workshop = 'workshop',
  IVL = 'interactive-videos',
}

export enum ProjectTabs {
  dashboard = 'dashboard',
  questions = 'questions',
  objectives = 'objectives',
  power = 'power',
  variables = 'variables',
  communications = 'communications',
  time = 'time',
  risk = 'risk',
  negotiate = 'negotiate',
  align = 'align',
}

export type PrintableProjectTabs = Exclude<ProjectTabs, ProjectTabs.dashboard>

export const printableProjectTabs = Object.values(ProjectTabs).filter(
  (tab) => tab !== ProjectTabs.dashboard
) as PrintableProjectTabs[]

export enum ProjectTabsSubcategories {
  CommunicationConnections = 'communicationconnections',
  CommunicationKeyMessages = 'communicationkeymessages',
  CommunicationExternalMessages = 'communicationexternalmessages',
  CommunicationInternalMessages = 'communicationinternalmessages',
  CommunicationQuestions = 'communicationquestions',
  NegotiateScenarios = 'negotiatescenarios',
  NegotiateDealTracker = 'negotiatedealtracker',
}

export const projectTabsSubcategories = Object.values(ProjectTabsSubcategories)

export enum SettingsTabs {
  users = 'users',
  bulkImports = 'bulk-imports',
  organizations = 'organizations',
}
export enum OrganizationTabs {
  users = 'users',
  settings = 'settings',
}
export enum LearningManageTabs {
  videos = 'videos',
  documents = 'documents',
  classes = 'classes',
}
export enum LearningManageClassTabs {
  details = 'details',
  users = 'users',
}
export enum WorkshopManageTabs {
  videos = 'videos',
  documents = 'documents',
  preWorkLibrary = 'pre-work-library',
}
export enum WorkshopResourcesTabs {
  resources = 'resources',
  preWork = 'pre-work',
  briefs = 'briefs',
  rules = 'rules',
}
export enum IVLManageTabs {
  videos = 'videos',
  courses = 'courses',
}
export enum IVLManageCourseTabs {
  details = 'details',
  users = 'users',
}

export type Tabs = LearningManageClassTabs | LearningManageTabs

export const baseProjectsRoute = `/${AppMode.NPT}/projects/[slug]`
export const baseFoldersRoute = `/${AppMode.NPT}/projects/folder/[id]`
export const routes = {
  root: '/',
  login: '/login',
  loginWithSso: '/login-with-sso',
  ssoError: '/sso-error',
  logout: '/logout',
  register: '/register',
  magicLink: '/magic-link',
  forgotEmail: '/forgot-email',
  resetPassword: '/reset-password',
  forgotPassword: '/forgot-password',
  workshopsCalendar: '/workshops-calendar',
  changePassword: '/change-password',
  zendeskSSOWarning: '/zendesk/sso-warning',
  learning: {
    index: `/${AppMode.Learning}/`,
    manage: {
      index: `/${AppMode.Learning}/manage`,
      tabs: (tab: LearningManageTabs) => {
        return `/${AppMode.Learning}/manage/${tab}`
      },
      class: {
        create: {
          new: (tab?: LearningManageClassTabs) => {
            const tabPath = resolveTabPath(LearningManageClassTabs.details, tab)

            return `${routes.learning.manage.tabs(LearningManageTabs.classes)}/add${tabPath}`
          },
          fromClassId: (classId: string, tab?: LearningManageClassTabs) => {
            const tabPath = resolveTabPath(LearningManageClassTabs.details, tab)

            return {
              pathname: `${routes.learning.manage.tabs(
                LearningManageTabs.classes
              )}/add/[fromClassId]${tabPath}`,
              query: { fromClassId: classId },
            }
          },
        },
        byId: (classId: string, tab?: LearningManageClassTabs) => {
          const tabPath = resolveTabPath(LearningManageClassTabs.details, tab)

          return {
            pathname: `${routes.learning.manage.tabs(
              LearningManageTabs.classes
            )}/[classId]${tabPath}`,
            query: { classId: classId },
          }
        },
      },
      videoById: (videoId: string) =>
        `${routes.learning.manage.tabs(LearningManageTabs.videos)}/${videoId}`,
      documentById: (documentId: string) =>
        `${routes.learning.manage.tabs(LearningManageTabs.documents)}/${documentId}`,
    },
    classes: `/${AppMode.Learning}/classes`,
    classById: (
      classId: string,
      classVideoId?: string,
      query?: { [key: string]: string | boolean | number }
    ) => {
      let url = `/${AppMode.Learning}/classes/${classId}`
      if (classVideoId) {
        url += `/videos/${classVideoId}`
      }
      if (query) {
        url += '?'
        Object.keys(query).forEach((key) => {
          url += `${key}=${query[key]}`
        })
      }
      return url
    },
  },
  lv1: {
    index: `/${AppMode.Lv1}/`,
    game: `/${AppMode.Lv1}/game`,
    dashboard: `/${AppMode.Lv1}/dashboard`,
    subgroups: `/${AppMode.Lv1}/subgroups`,
    mySubgroups: `/${AppMode.Lv1}/my-subgroups`,
    leaderboard: (accountId: string, subgroupId?: string) => {
      const accountUrl = `/${AppMode.Lv1}/${accountId}`
      if (subgroupId) {
        return `${accountUrl}/group/${subgroupId}`
      }
      return accountUrl
    },
    playerProfile: (accountId: string, userId: string) => `/${AppMode.Lv1}/${accountId}/${userId}`,
  },
  v2workshops: {
    index: `/v2workshops`,
    manage: {
      index: `/v2workshops/manage`,
      resourceManagement: {
        index: `/v2workshops/manage/resource-management`,
        tabs: (tab: WorkshopResourcesTabs) => {
          return `/v2workshops/manage/resource-management/${tab}`
        },
      },
      preWork: {
        index: `/v2workshops/manage/pre-work`,
        tabs: (tab: WorkshopManageTabs) => {
          return `/v2workshops/manage/pre-work/${tab}`
        },
        preWorkById: (preWorkId: string) =>
          `${routes.v2workshops.manage.preWork.tabs(WorkshopManageTabs.preWorkLibrary)}/${preWorkId}`,
      },
      briefs: `/v2workshops/manage/briefs`,
      videoById: (videoId: string) => `${routes.v2workshops.manage.index}/videos/${videoId}`,
      documentById: (wsDocumentId: string) =>
        `${routes.v2workshops.manage.index}/documents/${wsDocumentId}`,
    },
  },
  workshop: {
    preWorkshop: {
      profileQuestionnaire: `/${AppMode.Workshop}/pre-workshop/profile-questionnaire`,
      preWorkshopSurvey: `/${AppMode.Workshop}/pre-workshop/pre-workshop-survey`,
      preWork: `/${AppMode.Workshop}/pre-workshop/pre-work`,
      preWorkItemById: (
        preWorkItemId: string,
        query?: { [key: string]: string | boolean | number }
      ) => {
        let url = `${routes.workshop.preWorkshop.preWork}/${preWorkItemId}`

        if (query) {
          url += '?'
          Object.keys(query).forEach((key) => {
            url += `${key}=${query[key]}`
          })
        }

        return url
      },
    },
    index: `/${AppMode.Workshop}/`,
    dashboard: `/${AppMode.Workshop}/dashboard`,
    workshop: `/${AppMode.Workshop}/live`, // @TODO: readjust when workshop is migrated
    peerFeedbackSurvey: `/${AppMode.Workshop}/peer-feedback-survey`,
    workshopFeedback: `/${AppMode.Workshop}/workshop-feedback`,
    postWorkshopSurvey: `/${AppMode.Workshop}/post-workshop-survey`,
    profile: `/${AppMode.Workshop}/profile`,
    print: `/${AppMode.Workshop}/print`,
  },
  npt: {
    index: `/${AppMode.NPT}/`,
    dashboard: `/${AppMode.NPT}/dashboard`,
    variablesBank: `/${AppMode.NPT}/variables-bank`,
    projects: `/${AppMode.NPT}/projects`,
    archivedProjects: `/${AppMode.NPT}/archived-projects`,
    archivedFolderById: (projectId: string) =>
      `/${AppMode.NPT}/archived-projects/folder/${projectId}`,
    folderById: (projectId: string) => `/${AppMode.NPT}/projects/folder/${projectId}`,
    projectById: (projectId: string, tab?: ProjectTabs) => {
      const tabPath = resolveTabPath(ProjectTabs.dashboard, tab)
      return {
        pathname: `${baseProjectsRoute}${tabPath}`,
        query: { slug: projectId },
      }
    },
    projectLinkById: (projectId: string, tab?: ProjectTabs | 'print') => {
      const tabPath = resolveTabPath(ProjectTabs.dashboard, tab)

      return `/${AppMode.NPT}/projects/${projectId}${tabPath}`
    },
  },
  // Interactive Video Learning
  interactiveVideos: {
    index: `/${AppMode.IVL}/`,
    manage: {
      index: `/${AppMode.IVL}/manage`,
      tabs: (tab: IVLManageTabs) => {
        return `/${AppMode.IVL}/manage/${tab}`
      },
      course: {
        create: {
          new: (tab?: IVLManageCourseTabs) => {
            const tabPath = resolveTabPath(IVLManageCourseTabs.details, tab)

            return `${routes.interactiveVideos.manage.tabs(IVLManageTabs.courses)}/add${tabPath}`
          },
        },
        byId: (courseId: string, tab?: IVLManageCourseTabs) => {
          const tabPath = resolveTabPath(IVLManageCourseTabs.details, tab)

          return {
            pathname: `${routes.interactiveVideos.manage.tabs(IVLManageTabs.courses)}/[courseId]${tabPath}`,
            query: { courseId: courseId },
          }
        },
      },
      videoById: (videoId: string) =>
        `${routes.interactiveVideos.manage.tabs(IVLManageTabs.videos)}/${videoId}`,
    },
    courses: `/${AppMode.IVL}/courses`,
    selectCourse: `/${AppMode.IVL}/courses/select-course`,
    courseById: (
      courseId: string,
      courseVideoId?: string,
      query?: { [key: string]: string | boolean | number }
    ) => {
      let url = `/${AppMode.IVL}/courses/${courseId}`
      if (courseVideoId) {
        url += `/videos/${courseVideoId}`
      }
      if (query) {
        url += '?'
        Object.keys(query).forEach((key) => {
          url += `${key}=${query[key]}`
        })
      }
      return url
    },
    // org admins - courses and course users
    dashboard: {
      index: `/${AppMode.IVL}/dashboard`,
      course: {
        byId: (courseId: string) => {
          return `${routes.interactiveVideos.dashboard.index}/${courseId}`
        },
      },
    },
  },
  coaching: '/coaching',
  organizationStatistics: {
    index: '/organization-statistics',
    obstaclesEnablers: '/organization-statistics/obstacles-enablers',
    workshopSurvey: '/organization-statistics/workshop-survey',
  },
  // settings: '/settings',
  settings: {
    index: '/settings',
    tabs: (tab: SettingsTabs) => {
      return `/settings/${tab}`
    },
    organizationById: (accountId: string, tab?: OrganizationTabs) => {
      const tabPath = resolveTabPath(OrganizationTabs.users, tab)
      return {
        pathname: `${routes.settings.tabs(SettingsTabs.organizations)}/[accountId]${tabPath}`,
        query: { accountId: accountId },
      }
    },
    userById: (userId: string) => `${routes.settings.tabs(SettingsTabs.users)}/${userId}`,
  },
  profile: '/profile',
  privacyPolicy: '/privacy-policy',
  api: {
    vimeo: {
      uploadLink: `/api/vimeo/videos/upload-link`,
      videos: (vimeoId: string | number) => `/api/vimeo/videos/${vimeoId}`,
    },
    media: {
      uploadLink: `/api/media/upload-link`,
      view: (s3Key: string) => `/api/media/${s3Key}`,
      remove: (s3Key: string) => `/api/media/${s3Key}`,
    },
    google: {
      retrieveTokens: `/api/google/retrieve-tokens`,
      connectUser: `/api/google/connect-user`,
      disconnectUser: `/api/google/disconnect-user`,
    },
    learnworlds: {
      learnworldsSso: `/api/learnworlds/redirect-user`,
    },
    auth: {
      generateSecret: `/api/auth/generate-secret`,
    },
    responseLimit: '8mb',
  },
} as const

export const pages: NextAuthOptions['pages'] = {
  error: routes.login,
  signIn: routes.login,
  signOut: routes.logout,
  verifyRequest: routes.root,
}

export const publicRoutes: string[] = [
  routes.root,
  routes.login,
  routes.loginWithSso,
  routes.ssoError,
  routes.magicLink,
  routes.logout,
  routes.register,
  routes.resetPassword,
  routes.forgotPassword,
  routes.forgotEmail,
  routes.privacyPolicy,
  routes.zendeskSSOWarning,
]

export const tabToSection = (tab: ProjectTabs): ProjectSection => {
  return tab.toUpperCase() as ProjectSection
}
export const sectionToTab = (section: ProjectSection): ProjectTabs => {
  return section.toLowerCase() as ProjectTabs
}
export const routeToSection = (route: string) => {
  const tab = route.replace(baseProjectsRoute, '').replace('/', '')
  if (tab) {
    return tab.toUpperCase() as ProjectSection
  }
  return ProjectSection.Dashboard
}

const resolveTabPath = (rootTab: string, tab?: string) => {
  return !tab || tab === rootTab ? '' : `/${tab}`
}
